export class LocalStorageService {
    localStorage: Storage;

    constructor(localStorage: Storage) {
        console.log('Created LocalStorageService');
        this.localStorage = localStorage;
    }

    get(key: string) {
        // mandatory try catch here (anything can be in local storage)
        try {
            const data = this.localStorage.getItem(key);
            if (data) {
                return JSON.parse(data);
            } else {
                return undefined;
            }
        } catch (ex) {
            console.warn(ex);
            return undefined;
        }
    }

    // TODO - value can really be of any kind...
    // @ts-expect-error
    set(key: string, value): void {
        // mandatory try catch here (make sure you write json structure to local storage)
        try {
            this.localStorage.setItem(key, JSON.stringify(value));
        } catch (ex) {
            console.warn(ex);
        }
    }

    remove(key: string): void {
        this.localStorage.removeItem(key);
    }

    clear(): void {
        this.localStorage.clear();
    }
}

export const localStorageService = new LocalStorageService(localStorage);
