<template>
    <v-app-bar app color="primary" dark>
        <v-btn v-if="!isLoginRouteActivated()" text elevation="0" @click="navigateBack" class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
            Back
        </v-btn>
        <v-toolbar-title> {{ currentRouteName }}</v-toolbar-title>
        <v-btn v-if="!isLoginRouteActivated()" text elevation="0" @click="clearLocalStorageAndReset" class="ml-auto">
            Logout
            <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script type="ts">
import { hasHistory } from '@/methods/hasHistory';
import { getAppName } from '@/methods/getAppName';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class NavigationBar extends Vue {
    name = 'navigationBar';

    // data

    // methods
    navigateBack() {
        if (hasHistory()) {
            this.$router.go(-1);
        } else {
            this.$router.push('/');
        }
    }

    clearLocalStorageAndReset() {
        this.localStorageService.clear();
        window.location.reload();
    }

    isLoginRouteActivated() {
        return this.$route.path === '/login';
    }

    // computed

    get currentRouteName() {
        return `${getAppName()} - ${this.$route.name}`;
    }

    set currentRouteName(value) {
        // do nothing
    }
}
</script>
<style scoped></style>
