<template>
    <v-container>
        <v-row style="z-index: 9999;" class="v-btn--fixed v-btn--right mt-5 d-flex">
            <v-col cols="12">
                <v-btn class="mr-3" color="primary" dark @click="testMappings">
                    Test
                </v-btn>
                <v-btn color="success" @click="saveMappings">
                    Save
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-form ref="form">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-select
                                                @change="updateRelatedMappings()"
                                                label="Mapping type"
                                                :items="mappingTypes"
                                                v-model="mainMapping.type"
                                                :rules="[requiredRule]"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select
                                                label="Brand"
                                                @change="updateRelatedMappings()"
                                                :items="availableToValues({ type: 'brand' })"
                                                v-model="mainMapping.brands"
                                                :disabled="!['model', 'version'].includes(mainMapping.type)"
                                                :rules="[
                                                    (v) => !['model', 'version'].includes(mainMapping.type) || (!!v && v.length > 0) || 'Brand is required',
                                                ]"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-expansion-panels v-model="openedPanels" multiple>
                                                <v-expansion-panel v-for="p in panels" v-bind:key="p.index" :disabled="p.markedForDeletion">
                                                    <v-form :ref="`form_${p.index}`">
                                                        <v-expansion-panel-header>
                                                            <v-row>
                                                                <v-col>
                                                                    <span class="font-weight-medium">{{ p.mapping.partial ? 'Partial' : 'Exact' }} </span>
                                                                    <span class="text--secondary">mapping</span> <span class="text--secondary">from </span>
                                                                    <span class="font-weight-medium"> {{ p.mapping.from }} </span>
                                                                    <span class="text--secondary">to </span>
                                                                    <span class="font-weight-medium"> {{ p.mapping.to }}</span>
                                                                    <v-spacer></v-spacer>
                                                                </v-col>
                                                                <v-col v-if="!p.markedForDeletion && p.errors">
                                                                    <v-spacer></v-spacer>
                                                                    <span class="red--text">Error - this mapping will be excluded from Save</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content eager>
                                                            <v-row>
                                                                <v-col cols="12" class="d-flex flex-row align-baseline justify-end py-0">
                                                                    <v-switch
                                                                        class="p-0 mt-0 mb-0 mr-5"
                                                                        inset
                                                                        v-model="p.mapping.partial"
                                                                        :label="`Partial mapping`"
                                                                    ></v-switch>

                                                                    <v-btn
                                                                        class="p-0 mt-0 mb-0"
                                                                        text
                                                                        color="error"
                                                                        @click="removePanel(p.index)"
                                                                        :disabled="p.index < 1"
                                                                    >
                                                                        <v-icon>
                                                                            mdi-trash-can-outline
                                                                        </v-icon>
                                                                        Remove
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col cols="12" md="6" class="pt-0">
                                                                    <v-text-field
                                                                        label="From"
                                                                        v-model="p.mapping.from"
                                                                        hint="If you are entering multiple words make sure you separate them with Space"
                                                                        :rules="[requiredRule]"
                                                                    ></v-text-field>
                                                                </v-col>

                                                                <v-col cols="12" md="6" class="pt-0">
                                                                    <v-autocomplete
                                                                        v-model="p.mapping.to"
                                                                        :items="availableToValues(p.mapping)"
                                                                        label="To"
                                                                        :rules="[requiredRule]"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="6" class="pt-0">
                                                                    <v-select
                                                                        v-model="p.mapping.sites"
                                                                        :items="sites"
                                                                        clearable
                                                                        label="Sites"
                                                                        multiple
                                                                        chips
                                                                        deletable-chips
                                                                        placeholder="Applies for all sites"
                                                                        no-data-text="Applies for all sites"
                                                                    ></v-select>
                                                                </v-col>
                                                                <v-col cols="12" md="6" class="pt-0">
                                                                    <v-combobox
                                                                        v-model="p.mapping.excludes"
                                                                        hint="Mapping will not match if any of these values are present in value being mapped"
                                                                        label="Excluded values"
                                                                        chips
                                                                        deletable-chips
                                                                        color="blue"
                                                                        multiple
                                                                    ></v-combobox>
                                                                </v-col>
                                                            </v-row>
                                                        </v-expansion-panel-content>
                                                    </v-form>
                                                    <v-col v-if="p.markedForDeletion">
                                                        <v-spacer></v-spacer>
                                                        <span class="red--text">Marked for deletion</span>
                                                        <v-btn class="p-0 mt-0 mb-0" text color="info" @click.stop="undoRemovePanel(p.index)">
                                                            <v-icon>
                                                                mdi-trash-can-outline
                                                            </v-icon>
                                                            Undo
                                                        </v-btn>
                                                    </v-col>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="text-center">
                                            <v-btn class="text-center" @click="addPanel()">
                                                <v-icon>
                                                    mdi-plus
                                                </v-icon>
                                                Duplicate mapping
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-progress-linear indeterminate rounded height="6" v-if="showLoader"></v-progress-linear>

                        <v-row class="mt-5">
                            <v-col>
                                <span>Mapping created {{ mainMapping.createdAt | formatDate }}</span>
                                <br />
                                <span>Mapping updated {{ mainMapping.updatedAt | formatDate }}</span>
                                <br />
                                <span>Mapping created by {{ mainMapping.author }}</span>
                                <tooltip :tooltip-text="mainMapping.history"></tooltip>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions></v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <mapping-difference :mapping-difference="mappingDifference" :mapping="mainMapping"></mapping-difference>
        </v-row>
    </v-container>
</template>

<script type="ts">
import { getEmptyMappingDifferenceItem } from '@/methods/getEmptyMappingDifferenceItem';
import Component, { mixins } from 'vue-class-component';
import hasMappingTypes from '@/mixins/hasMappingTypes';
import hasSites from '@/mixins/hasSites';
import { getFrontEndMapping } from '@/methods/getFrontEndMapping';
import MappingDifference from '@/components/MappingDifference.vue';
import { getRelatedMappings } from '@/methods/getRelatedMappings';
import Tooltip from '@/components/Tooltip.vue';
import { getBackEndMapping } from '@/methods/getBackEndMapping';
import hasMappingCrud from '@/mixins/hasMappingCrud';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { StoreMutationsEnum } from '@/shared/enums/storeMutations';
import { StoreActionsEnum } from '@/shared/enums/storeActions';
import { StoreGettersEnum } from '@/shared/enums/storeGetters';

@Component({
    components: { Tooltip, MappingDifference },
    computed: {
        ...mapState(['mappings']),
        ...mapGetters([StoreGettersEnum.availableToValues]),
    },
    methods: {
        ...mapMutations([StoreMutationsEnum.displaySnackSuccess, StoreMutationsEnum.displaySnackError]),
        ...mapActions([StoreActionsEnum.fetchMappings, StoreActionsEnum.fetchB2bVehicles])
    }
})
export default class Main extends mixins(hasMappingTypes, hasSites, hasMappingCrud) {

    name = 'mapping';

    // data
    mappingDifference = getEmptyMappingDifferenceItem();
    mainMapping = {};

    panels = [];
    openedPanels = [];

    showLoader = false;

    // methods

    requiredRule(v) {
        return (!!v && v.length > 0) || 'Item is required.';
    }

    async created() {
        const mappingsLoaded = await this.fetchMappings();
        const b2bVehiclesLoaded = await this.fetchB2bVehicles();
        if (!(mappingsLoaded && b2bVehiclesLoaded)) {
            return this.$router.push('/');
        }

        const _id = this.$route.params.id && decodeURIComponent(this.$route.params.id);
        const existingMapping = _id && this.mappings.find((mapping) => mapping._id === _id);

        this.mainMapping = existingMapping ?? getFrontEndMapping();
        if (!this.mainMapping?.author) {
            this.mainMapping.author = this.$store.state.user.name;
        }
        this.addPanel(JSON.parse(JSON.stringify(this.mainMapping)));

        for (const mapping of getRelatedMappings({ mapping: this.mainMapping, mappings: this.mappings })) {
            this.addPanel(mapping);
        }

        if (this.openedPanels.length > 1) {
            this.closeAllPanels();
        }
    }

    addPanel(mapping) {
        const nextIndex = this.panels.length;
        const _mapping = mapping ?? {
            ...getFrontEndMapping(),
            ...this.mainMapping?.type && { type: this.mainMapping.type },
            ...this.mainMapping?.brands && { brands: this.mainMapping.brands },
            ...this.mainMapping?.from && { from: this.mainMapping.from },
            ...this.mainMapping?.to && { to: this.mainMapping.to },
            ...this.mainMapping?.partial && { partial: this.mainMapping.partial },
        };

        if (!mapping && !_mapping?.author) {
            _mapping.author = this.$store.state.user.name;
        }
        this.panels.push({
            index: nextIndex,
            mapping: _mapping,
            showLoader: false,
            errors: false,
            markedForDeletion: false,
            userAdded: !mapping?._id,
        });
        this.openedPanels.push(nextIndex);
    }

    closeAllPanels() {
        this.openedPanels = [];
    }

    removePanel(i) {

        // holy f***

        const panelToDelete = this.panels.find(p => p.index === i);

        if (panelToDelete?.userAdded) {

            // if user added a panel, truly delete it

            this.panels = this.panels.filter(p => p.index !== i); // delete only panels added by the user
            this.openedPanels = this.openedPanels.filter(el => el !== i);

            const previouslyOpened = this.panels.filter(p => this.openedPanels.some(el => el === p.index));

            // recalculate indexes
            this.panels = this.panels.map((p, index) => {
                p.index = index;
                return p;
            });
            this.openedPanels = previouslyOpened.map(p => p.index);
            return;
        }

        this.openedPanels = this.openedPanels.filter(el => el !== i);
        panelToDelete.markedForDeletion = true;

    }

    undoRemovePanel(i) {

        const panelToUndo = this.panels.find(p => p.index === i);
        if (!panelToUndo.userAdded) {
            panelToUndo.markedForDeletion = false;
        }

    }

    updateRelatedMappings() {

        for (const { mapping } of this.panels) {
            if (this.mainMapping.brands && this.mainMapping.brands !== mapping.brands) {
                mapping.brands = this.mainMapping.brands;
            }
            if (this.mainMapping.type && this.mainMapping.type !== mapping.type) {
                mapping.type = this.mainMapping.type;
            }
        }
    }

    validateMappings() {
        for (const panel of this.panels) {
            // If the mapping is to be deleted, do not check if the mapping is correct.
            if (!panel.markedForDeletion) {
                panel.errors = !this?.$refs?.[`form_${panel.index}`]?.[0]?.validate();
            } else {
                // There was an error before the user deleted the mapping
                panel.errors = undefined;
            }
        }
        return this.panels.some(panel => panel.errors);
    }

    async testMappings() {

        if (this.validateMappings()) {
            this.displaySnackError('Check input data!');
            return;
        }

        const mappings = [];
        const mappingsToBeDeleted = [];

        for (const panel of this.panels) {
            if (panel.errors) {
                continue; // skip mappings with incomplete form
            }
            this.showLoader = true;

            if (panel.markedForDeletion) {
                mappingsToBeDeleted.push(getBackEndMapping(panel.mapping));
                continue;
            }

            mappings.push(getBackEndMapping(panel.mapping));
        }

         const mappingDifference = await this.apiService.getMappingDifference({ mappings, mappingsToBeDeleted });
         if (mappingDifference) {
             this.displaySnackSuccess("Fetching mapping differences successful");
             this.mappingDifference = mappingDifference;
         } else {
             this.displaySnackError("Fetching mapping differences failed");
        }

        this.showLoader = false;
    }

    async saveMappings() {

        if (this.validateMappings()) {
            this.displaySnackError('Check input data!');
            return;
        }

        for (const panel of this.panels) {

            if (panel.errors) {
                continue; // skip mappings with incomplete form
            }

            this.showLoader = true;

            if (panel.markedForDeletion) {
                await this.deleteMapping({ mapping: panel.mapping })
                continue;
            }

            if (panel.mapping._id) {
                await this.editMapping({ mapping: panel.mapping })
                continue;
            }

            await this.saveNewMapping({ mapping: panel.mapping });

        }

        await this.fetchMappings({ force: true });
        this.showLoader = false;

        return this.$router.go(-1);
    }

    // computed

}
</script>

<style scoped></style>
