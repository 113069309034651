var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showMappingDifference)?_c('v-container',[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h3',[_vm._v("Test results")])]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" All parsed "+_vm._s(_vm.mapping.type ? _vm.mapping.type + 's' : '')+" for "+_vm._s(_vm.mapping.brands)+" ")]),_c('v-card-text',[_c('v-virtual-scroll',{attrs:{"items":_vm.mappingDifference.allBrandValues,"item-height":30,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)]}}],null,false,2651097925)},[_vm._v(" > ")])],1)],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Unresolved mappings that will become obsolete ")]),_c('v-card-text',[_c('v-virtual-scroll',{attrs:{"items":_vm.mappingDifference.obsoleteMappings,"item-height":30,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.from))])],1)]}}],null,false,2717130237)},[_vm._v(" > ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Currently mapping into "+_vm._s(_vm.mapping.to)+" "+_vm._s(_vm.mappingDifference.currentMappingResults.length > 0 ? ("(" + (_vm.mappingDifference.currentMappingResults.length) + ")") : '')+" ")]),_c('v-card-text',[_c('v-virtual-scroll',{attrs:{"items":_vm.mappingDifference.currentMappingResults,"item-height":30,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.rawModel)+" "),(!_vm.mappingDifference.newMappingResults.find(function (el) { return el.rawModel === item.rawModel; }))?_c('v-icon',{attrs:{"title":"Will not be mapped anymore if the mapping is saved","color":"danger"}},[_vm._v(" mdi-minus-circle ")]):_vm._e()],1)],1)]}}],null,false,2900912890)},[_vm._v(" > ")])],1)],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Will map to "+_vm._s(_vm.mapping.to)+" after the update "+_vm._s(_vm.mappingDifference.newMappingResults.length > 0 ? ("(" + (_vm.mappingDifference.newMappingResults.length) + ")") : '')+" ")]),_c('v-card-text',[_c('v-virtual-scroll',{attrs:{"items":_vm.mappingDifference.newMappingResults,"item-height":30,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.rawModel)+" "),(!_vm.mappingDifference.currentMappingResults.find(function (el) { return el.rawModel === item.rawModel; }))?_c('v-icon',{attrs:{"title":"Will be additionally mapped if the mapping is saved","color":"success"}},[_vm._v(" mdi-plus-circle ")]):_vm._e()],1)],1)]}}],null,false,855088763)},[_vm._v(" > ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Mappings mapped into "+_vm._s(_vm.mapping.to)+" "+_vm._s(_vm.mappingDifference.length > 0 ? ("(" + (_vm.mappingDifference.relatedMappings.length) + ")") : '')+" ")]),_c('v-card-text',[_c('v-virtual-scroll',{attrs:{"items":_vm.mappingDifference.relatedMappings,"item-height":30,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.from)+" "),(item.partial === true)?_c('v-icon',[_vm._v("mdi-arrow-right-thin-circle-outline")]):_c('v-icon',[_vm._v("mdi-arrow-right-thin-circle-outline")]),_vm._v(" "+_vm._s(item.to)+" ")],1)],1)]}}],null,false,2772109090)},[_vm._v(" > ")])],1)],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Mappings mapped from "+_vm._s(_vm.mapping.from)+" "+_vm._s(_vm.mappingDifference.length > 0 ? ("(" + (_vm.mappingDifference.mappingsContainingFrom.length) + ")") : '')+" ")]),_c('v-card-text',[_c('v-virtual-scroll',{attrs:{"items":_vm.mappingDifference.mappingsContainingFrom,"item-height":30,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.from)+" "),(item.partial === true)?_c('v-icon',[_vm._v("mdi-arrow-right-thin-circle-outline")]):_c('v-icon',[_vm._v("mdi-arrow-right-thin-circle-outline")]),_c('span',{class:{ 'font-italic': !item.to }},[_vm._v(" "+_vm._s(item.to ? item.to : 'Unresolved')+" ")])],1)],1)]}}],null,false,2931766256)},[_vm._v(" > ")])],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }