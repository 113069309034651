import Vue from 'vue';
import Component from 'vue-class-component';
import { SitesEnum } from '@/shared/enums/sites';
@Component
export default class HasSites extends Vue {
    public name = 'hasSites';

    // data
    sites = Object.values(SitesEnum);
}
