import Vue from 'vue';
import Component from 'vue-class-component';
import { MappingTypesEnum } from '@/shared/enums/mappingTypes';

@Component
export default class HasMappingTypes extends Vue {
    name = 'hasMappingTypes';

    // data
    mappingTypes = Object.values(MappingTypesEnum);
}
