import { BackEndMapping } from '@/shared/interfaces/BackEndMapping';
import { FrontEndMapping } from '@/shared/interfaces/FrontEndMapping';
import { normalizeString } from '@/methods/normalizeString';
import { MappingStatusesEnum } from '@/shared/enums/mappingStatuses';

const getFrontEndMapping = (mapping: BackEndMapping): FrontEndMapping => {
    const timestamp = new Date().toISOString();
    const m: FrontEndMapping = {
        _id: mapping?._id,
        appType: mapping?.appType,
        type: mapping?.type,
        from: normalizeString(mapping?.from),
        to: mapping?.to,
        excludes: mapping?.excludes ?? [],
        partial: mapping?.partial ?? false,
        status: mapping?.status ?? MappingStatusesEnum.RESOLVED,
        history: mapping?.history ?? [],
        sites: mapping?.sites ?? [],
        urls: mapping?.urls ?? [],
        author: mapping?.author,
        createdAt: mapping?.createdAt ?? timestamp,
        updatedAt: mapping?.updatedAt ?? timestamp,
        brands: mapping?.brands?.[0] || undefined, // TEMP FIX FOR INVALID VALUES IN ELASTIC DB
        bodyTypes: mapping?.bodyTypes?.[0] || undefined,
        models: mapping?.models?.[0] || undefined,
        versions: mapping?.versions?.[0] || undefined,
    };

    return m;
};

export { getFrontEndMapping };
