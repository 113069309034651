import { apiService, ApiService } from '@/services/api.service';
import { localStorageService, LocalStorageService } from '@/services/localStorage.service';
import { FrontEndMapping } from '@/shared/interfaces/FrontEndMapping';
import { getFrontEndMapping } from '@/methods/getFrontEndMapping';
import { B2bVehicles } from '@/shared/interfaces/B2bVehicles';

export class EntitiesService {
    apiService: ApiService;
    localStorageService: LocalStorageService;

    constructor(apiService: ApiService, localStorageService: LocalStorageService) {
        console.log('Created EntitiesService');
        this.apiService = apiService;
        this.localStorageService = localStorageService;
    }

    async getMappings({ force = false } = {}): Promise<FrontEndMapping[]> {
        if (this.localStorageService.get('mappings') && !force) {
            return this.localStorageService.get('mappings') as FrontEndMapping[];
        } else {
            const mappings: FrontEndMapping[] = (await this.apiService.getMappings())?.map(getFrontEndMapping);
            this.localStorageService.set('mappings', mappings);
            return mappings;
        }
    }

    async getB2bVehicles({ force = false } = {}): Promise<B2bVehicles> {
        if (this.localStorageService.get('b2bVehicles') && !force) {
            return this.localStorageService.get('b2bVehicles') as B2bVehicles;
        } else {
            const b2bVehicles: B2bVehicles = await this.apiService.getB2bVehicles();
            this.localStorageService.set('b2bVehicles', b2bVehicles);
            return b2bVehicles;
        }
    }
}

export const entitiesService = new EntitiesService(apiService, localStorageService);
