import Vue from 'vue';
import Component from 'vue-class-component';
import { FrontEndMapping } from '@/shared/interfaces/FrontEndMapping';
import { getBackEndMapping } from '@/methods/getBackEndMapping';
import { apiService } from '@/services/api.service';
import { BackEndMapping } from '@/shared/interfaces/BackEndMapping';
import { MappingStatusesEnum } from '@/shared/enums/mappingStatuses';
import { StoreMutationsEnum } from '@/shared/enums/storeMutations';

@Component // TODO - add vuex maps here
export default class HasMappingCrud extends Vue {
    name = 'hasMappingCrud';

    // data

    // methods

    async saveNewMapping({ mapping }: { mapping: FrontEndMapping }): Promise<boolean> {
        if (mapping._id) {
            return false;
        }
        const beMapping = getBackEndMapping(mapping);
        if (!beMapping.author) {
            beMapping.author = this.$store.state.user.name;
        }
        const historyRecord = this.appendToHistoryAndReturnHistoryRecord({ mapping: beMapping, text: 'saving mapping' });
        const postResponse = await apiService.createMapping(beMapping);
        if (postResponse?.status === 201) {
            if (!mapping.author) {
                mapping.author = this.$store.state.user.name;
            }
            mapping.history?.push(historyRecord);
            this.$store.commit(StoreMutationsEnum.displaySnackSuccess, `New mapping ${mapping.from} -> ${mapping.to} successfully created`);
        } else {
            this.$store.commit(StoreMutationsEnum.displaySnackError, `Creating new mapping ${mapping.from} -> ${mapping.to} failed`);
        }
        return postResponse?.status === 201;
    }

    async deleteMapping({ mapping }: { mapping: FrontEndMapping }): Promise<boolean> {
        if (!mapping._id) {
            return false;
        }
        const deleteResponse = await apiService.deleteMapping(mapping._id);
        if (deleteResponse?.status === 200) {
            // this.$store.commit('mappings.remove', mapping._id); TODO
            this.$store.commit(StoreMutationsEnum.displaySnackSuccess, `Mapping ${mapping.from} -> ${mapping.to} successfully deleted`);
        } else {
            this.$store.commit(StoreMutationsEnum.displaySnackError, `Deleting mapping ${mapping.from} -> ${mapping.to} failed`);
        }
        return deleteResponse?.status === 200;
    }

    async editMapping({ mapping }: { mapping: FrontEndMapping }): Promise<boolean> {
        if (!mapping._id) {
            return false;
        }
        const beMapping = getBackEndMapping(mapping);
        if (beMapping.status === MappingStatusesEnum.UNRESOLVED && beMapping.to) {
            beMapping.status = MappingStatusesEnum.RESOLVED;
        }
        beMapping.updatedAt = new Date().toISOString();
        const historyRecord = this.appendToHistoryAndReturnHistoryRecord({ mapping: beMapping, text: 'editing mapping' });
        const putResponse = await apiService.updateMapping({
            _id: mapping._id,
            mapping: beMapping,
        });
        if (putResponse?.status === 200) {
            if (mapping.status === MappingStatusesEnum.UNRESOLVED && mapping.to) {
                mapping.status = MappingStatusesEnum.RESOLVED;
            }
            mapping.updatedAt = beMapping.updatedAt;
            mapping.history?.push(historyRecord);
            this.$store.commit(StoreMutationsEnum.displaySnackSuccess, `Mapping ${mapping.from} -> ${mapping.to} successfully edited`);
        } else {
            this.$store.commit(StoreMutationsEnum.displaySnackError, `Editing mapping ${mapping.from} -> ${mapping.to} failed`);
        }
        return putResponse?.status === 200;
    }

    async hideMapping({ mapping }: { mapping: FrontEndMapping }): Promise<boolean> {
        if (!mapping._id) {
            return false;
        }
        const beMapping = getBackEndMapping(mapping);
        beMapping.updatedAt = new Date().toISOString();
        beMapping.hidden = true;
        this.appendToHistoryAndReturnHistoryRecord({ mapping: beMapping, text: 'hiding mapping' });
        const putResponse = await apiService.updateMapping({
            _id: mapping._id,
            mapping: beMapping,
        });
        if (putResponse?.status === 200) {
            // this.$store.commit('mappings.remove', mapping._id); TODO
            this.$store.commit(StoreMutationsEnum.displaySnackSuccess, `Mapping ${mapping.from} -> ${mapping.to} successfully hid`);
        } else {
            this.$store.commit(StoreMutationsEnum.displaySnackError, `Hiding mapping ${mapping.from} -> ${mapping.to} failed`);
        }
        return putResponse?.status === 200;
    }

    private appendToHistoryAndReturnHistoryRecord({ mapping, text }: { mapping: BackEndMapping; text: string }): {} {
        const historyRecord = { username: this.$store.state.user.name, text, timestamp: mapping.updatedAt };
        mapping.history?.push(historyRecord);
        return historyRecord;
    }
}
