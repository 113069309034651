import Vue from 'vue';
import Vuex from 'vuex';
import { StoreMutationsEnum } from '@/shared/enums/storeMutations';
import { entitiesService } from '@/services/entities.service';
import { StoreActionsEnum } from '@/shared/enums/storeActions';
import { FrontEndMapping } from '@/shared/interfaces/FrontEndMapping';
import { B2bVehicles } from '@/shared/interfaces/B2bVehicles';
import { MappingTypesEnum } from '@/shared/enums/mappingTypes';
import { StoreGettersEnum } from '@/shared/enums/storeGetters';
import { localStorageService } from '../services/localStorage.service';

Vue.use(Vuex);

const yesNoDialog = {
    isShown: false,
    text: '',
    title: 'Careful',
    context: null,
    params: null,
    resolvedTo: null,
};

const snack = {
    isShown: false,
    text: '',
    color: '',
    context: null,
    params: null,
    resolvedTo: null,
};

const user = {
    name: localStorageService.get('username') || '',
};

const mappings: FrontEndMapping[] = [];

const b2bVehicles: B2bVehicles = {} as B2bVehicles;

const store = new Vuex.Store({
    state: {
        yesNoDialog: { ...yesNoDialog },
        snack: { ...snack },
        user: { ...user },
        mappings: [...mappings],
        b2bVehicles: { ...b2bVehicles },
    },
    getters: {
        [StoreGettersEnum.filteredMappings]: (state) => {
            return ({ appType, mappingType, mappingStatus }: { appType: string; mappingType: string; mappingStatus: string }): FrontEndMapping[] => {
                return state.mappings.filter((el) => {
                    // checks...
                    if (!['resolved', 'unresolved'].includes(el.status)) {
                        console.error('This mapping is not resolved or unresolved, but something else... CHECK DB', el);
                    }
                    if (el.status === 'unresolved' && el.to) {
                        console.error('This mapping is unresolved, but has defined .to value... CHECK DB', el);
                    }

                    const isCorrectType = mappingType === 'All' || mappingType === el.type;
                    const isCorrectStatus = mappingStatus === 'All' || mappingStatus === el.status;
                    const isCorrectAppType = appType === 'All' || appType === el.appType;

                    return isCorrectType && isCorrectStatus && isCorrectAppType && isCorrectAppType;
                });
            };
        },
        [StoreGettersEnum.availableToValues]: (state) => {
            return ({ type, brands }: { type: string; brands?: string }) => {
                switch (type) {
                    case MappingTypesEnum.BRAND:
                    case MappingTypesEnum.BODY_TYPE:
                        if (state.b2bVehicles?.[type]) {
                            return Object.keys(state.b2bVehicles[type]);
                        }
                        return undefined;

                    case MappingTypesEnum.MODEL:
                    case MappingTypesEnum.VERSION:
                        if (brands && state.b2bVehicles?.brand?.[brands]?.[type]) {
                            return Object.keys(state.b2bVehicles.brand[brands][type]);
                        }
                        return undefined;

                    default:
                        return undefined;
                }
            };
        },
    },
    mutations: {
        [StoreMutationsEnum.displayYesNoDialog]: (state, payload): void => {
            state.yesNoDialog = { ...state.yesNoDialog, ...payload, isShown: true };
        },
        [StoreMutationsEnum.clearYesNoDialog]: (state, payload): void => {
            state.yesNoDialog = { ...state.yesNoDialog, ...yesNoDialog, ...payload, isShown: false };
        },
        [StoreMutationsEnum.displaySnackError]: (state, payload): void => {
            state.snack = { ...state.snack, color: 'error', text: payload || `An error occurred`, isShown: true };
        },
        [StoreMutationsEnum.displaySnackSuccess]: (state, payload): void => {
            state.snack = { ...state.snack, color: 'success', text: payload || `Success`, isShown: true };
        },
        [StoreMutationsEnum.clearSnack]: (state, payload): void => {
            state.snack = { ...state.snack, ...snack, ...payload, isShown: false };
        },
        [StoreMutationsEnum.setUsername]: (state, payload): void => {
            state.user.name = payload;
        },
        [StoreMutationsEnum.setMappings]: (state, payload): void => {
            state.mappings = payload;
        },
        [StoreMutationsEnum.setB2bVehicles]: (state, payload): void => {
            state.b2bVehicles = payload;
        },
    },
    actions: {
        [StoreActionsEnum.fetchMappings]: async (context, payload): Promise<boolean> => {
            const mappings = await entitiesService.getMappings({ force: payload?.force });
            if (mappings) {
                context.commit(StoreMutationsEnum.setMappings, mappings);
            } else {
                context.commit(StoreMutationsEnum.displaySnackError, 'Fetching mappings failed');
            }
            return Boolean(mappings);
        },
        [StoreActionsEnum.fetchB2bVehicles]: async (context, payload): Promise<boolean> => {
            const b2bVehicles = await entitiesService.getB2bVehicles({ force: payload?.force });
            if (b2bVehicles) {
                context.commit(StoreMutationsEnum.setB2bVehicles, b2bVehicles);
            } else {
                context.commit(StoreMutationsEnum.displaySnackError, 'Fetching b2b vehicles failed');
            }
            return Boolean(b2bVehicles);
        },
    },
    modules: {},
});

export default store;
