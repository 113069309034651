export enum StoreMutationsEnum {
    'displayYesNoDialog' = 'displayYesNoDialog',
    'clearYesNoDialog' = 'clearYesNoDialog',
    'displaySnackError' = 'displaySnackError',
    'displaySnackSuccess' = 'displaySnackSuccess',
    'clearSnack' = 'clearSnack',
    'setUsername' = 'setUsername',
    'setMappings' = 'setMappings',
    'setB2bVehicles' = 'setB2bVehicles',
}
