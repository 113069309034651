import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import { LocalStorageService, localStorageService } from '@/services/localStorage.service';
import { BackEndMapping } from '@/shared/interfaces/BackEndMapping';
import { B2bVehicles } from '@/shared/interfaces/B2bVehicles';

export class ApiService {
    axiosInstance: AxiosInstance;

    constructor(axios: AxiosStatic, localStorageService: LocalStorageService) {
        console.log('Created ApiService');

        const axiosInstance = axios.create({
            baseURL: `${process.env.VUE_APP_MARKET_STUDY_BASE_URL}/api/v1/mapping`,
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axiosInstance.interceptors.request.use(
            (ok: AxiosRequestConfig) => {
                const token = localStorageService.get('token');
                if (token) {
                    ok.headers.Authorization = token;
                }
                return ok;
            },
            (ex: AxiosError) => {
                console.warn(ex);
                return ex;
            },
        );

        axiosInstance.interceptors.response.use(
            (ok: AxiosResponse) => {
                return ok;
            },
            (ex: AxiosError) => {
                if (ex?.response?.status === 401) {
                    console.log('Invalidating token');
                    localStorageService.clear();
                    window.location.reload();
                }
                console.warn(ex);
                return ex;
            },
        );

        this.axiosInstance = axiosInstance;
    }

    async login({ username, password }: { username: string; password: string }) {
        return (await this.axiosInstance.post('/login', { username, password }))?.data;
    }

    async getMappings(): Promise<BackEndMapping[]> {
        return (await this.axiosInstance.get(''))?.data?.mappings;
    }

    async getB2bVehicles(): Promise<B2bVehicles> {
        return (await this.axiosInstance.get('/b2b-vehicles-hierarchically'))?.data;
    }

    async createMapping(mapping: {}) {
        return this.axiosInstance.post('', mapping);
    }

    async deleteMapping(_id: string) {
        return this.axiosInstance.delete(`/${encodeURIComponent(_id)}`);
    }

    async updateMapping({ _id, mapping }: { _id: string; mapping: BackEndMapping }) {
        return this.axiosInstance.put(`/${encodeURIComponent(_id)}`, mapping);
    }

    async triggerMappingsCopy() {
        return (await this.axiosInstance.post(`/trigger-copy`))?.data;
    }

    async refreshVehicles() {
        return (await this.axiosInstance.post(`/refresh-elastic-vehicles`))?.data;
    }

    async getMappingDifference(mappingDifference: {}) {
        return (await this.axiosInstance.post('/get-mapping-difference', mappingDifference))?.data;
    }
}

export const apiService = new ApiService(axios, localStorageService);
