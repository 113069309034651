<template>
    <!-- App.vue -->

    <v-app>
        <v-app-bar app color="primary" dark>
            <navigation-bar></navigation-bar>
        </v-app-bar>

        <v-main>
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>

        <yes-no-modal></yes-no-modal>

        <toaster></toaster>

        <v-footer app> </v-footer>
    </v-app>
</template>

<script language="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import YesNoModal from '@/components/YesNoDialog.vue';
import Toaster from '@/components/Toaster.vue';
import NavigationBar from '@/components/NavigationBar.vue';

@Component({
    components: {
        NavigationBar,
        Toaster,
        YesNoModal,
    },
})
export default class App extends Vue {
    name = 'app';

    // data

    // methods
}
</script>

<style scoped></style>
