import { BackEndMapping } from '@/shared/interfaces/BackEndMapping';
import { FrontEndMapping } from '@/shared/interfaces/FrontEndMapping';
import { normalizeString } from '@/methods/normalizeString';
import { MappingStatusesEnum } from '@/shared/enums/mappingStatuses';
import { AppTypesEnum } from '@/shared/enums/appTypes';

const getBackEndMapping = (mapping: FrontEndMapping): BackEndMapping => {
    const timestamp = new Date().toISOString();
    const m: BackEndMapping = {
        _id: mapping?._id,
        appType: mapping?.appType ?? AppTypesEnum.MS,
        type: mapping?.type,
        from: normalizeString(mapping?.from),
        to: mapping?.to,
        excludes: mapping?.excludes ?? [],
        partial: mapping?.partial ?? false,
        status: mapping?.status ?? MappingStatusesEnum.RESOLVED,
        history: mapping?.history ?? [],
        sites: mapping?.sites ?? [],
        urls: mapping?.urls ?? [],
        author: mapping?.author,
        createdAt: mapping?.createdAt ?? timestamp,
        updatedAt: mapping?.updatedAt ?? timestamp,
    };

    if (mapping.brands || mapping.type === 'brand') {
        m.brands = mapping.brands ? [mapping.brands] : [mapping.to];
    }

    if (mapping.models) {
        m.models = [mapping.models];
    }

    if (mapping.versions) {
        m.versions = [mapping.versions];
    }

    if (mapping.bodyTypes) {
        m.bodyTypes = [mapping.bodyTypes];
    }

    return m;
};

export { getBackEndMapping };
