<template>
    <v-container v-if="showMappingDifference">
        <v-expansion-panels v-model="opened" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header><h3>Test results</h3></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <!-- All values -->
                        <v-col md="6" cols="12">
                            <v-card>
                                <v-card-title> All parsed {{ mapping.type ? mapping.type + 's' : '' }} for {{ mapping.brands }} </v-card-title>
                                <v-card-text>
                                    <v-virtual-scroll :items="mappingDifference.allBrandValues" :item-height="30" height="400">
                                        <template v-slot="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        >
                                    </v-virtual-scroll>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!-- Mappings that will be removed -->
                        <v-col md="6" cols="12">
                            <v-card>
                                <v-card-title>
                                    Unresolved mappings that will become obsolete
                                </v-card-title>
                                <v-card-text>
                                    <v-virtual-scroll :items="mappingDifference.obsoleteMappings" :item-height="30" height="400">
                                        <template v-slot="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.from }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        >
                                    </v-virtual-scroll>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- Currently matching -->
                    <v-row>
                        <v-col md="6" cols="12">
                            <v-card>
                                <v-card-title>
                                    Currently mapping into {{ mapping.to }}
                                    {{ mappingDifference.currentMappingResults.length > 0 ? `(${mappingDifference.currentMappingResults.length})` : '' }}
                                </v-card-title>
                                <v-card-text>
                                    <v-virtual-scroll :items="mappingDifference.currentMappingResults" :item-height="30" height="400">
                                        <template v-slot="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.rawModel }}
                                                    <v-icon
                                                        title="Will not be mapped anymore if the mapping is saved"
                                                        color="danger"
                                                        v-if="!mappingDifference.newMappingResults.find((el) => el.rawModel === item.rawModel)"
                                                    >
                                                        mdi-minus-circle
                                                    </v-icon>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        >
                                    </v-virtual-scroll>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!-- Will match from now on -->
                        <v-col md="6" cols="12">
                            <v-card>
                                <v-card-title>
                                    Will map to {{ mapping.to }} after the update
                                    {{ mappingDifference.newMappingResults.length > 0 ? `(${mappingDifference.newMappingResults.length})` : '' }}
                                </v-card-title>
                                <v-card-text>
                                    <v-virtual-scroll :items="mappingDifference.newMappingResults" :item-height="30" height="400">
                                        <template v-slot="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.rawModel }}
                                                    <v-icon
                                                        title="Will be additionally mapped if the mapping is saved"
                                                        color="success"
                                                        v-if="!mappingDifference.currentMappingResults.find((el) => el.rawModel === item.rawModel)"
                                                    >
                                                        mdi-plus-circle
                                                    </v-icon>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        >
                                    </v-virtual-scroll>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="6" cols="12">
                            <v-card>
                                <v-card-title>
                                    Mappings mapped into {{ mapping.to }}
                                    {{ mappingDifference.length > 0 ? `(${mappingDifference.relatedMappings.length})` : '' }}
                                </v-card-title>
                                <v-card-text>
                                    <v-virtual-scroll :items="mappingDifference.relatedMappings" :item-height="30" height="400">
                                        <template v-slot="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.from }}
                                                    <v-icon v-if="item.partial === true">mdi-arrow-right-thin-circle-outline</v-icon>
                                                    <v-icon v-else>mdi-arrow-right-thin-circle-outline</v-icon>
                                                    {{ item.to }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        >
                                    </v-virtual-scroll>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col md="6" cols="12">
                            <v-card>
                                <v-card-title>
                                    Mappings mapped from {{ mapping.from }}
                                    {{ mappingDifference.length > 0 ? `(${mappingDifference.mappingsContainingFrom.length})` : '' }}
                                </v-card-title>
                                <v-card-text>
                                    <v-virtual-scroll :items="mappingDifference.mappingsContainingFrom" :item-height="30" height="400">
                                        <template v-slot="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.from }}

                                                    <v-icon v-if="item.partial === true">mdi-arrow-right-thin-circle-outline</v-icon>
                                                    <v-icon v-else>mdi-arrow-right-thin-circle-outline</v-icon>

                                                    <span class="" v-bind:class="{ 'font-italic': !item.to }">
                                                        {{ item.to ? item.to : 'Unresolved' }}
                                                    </span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        >
                                    </v-virtual-scroll>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script type="ts">
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
    props: ['mappingDifference', 'mapping']
})
export default class MappingDifference extends Vue {
    name = 'mappingDifference';

    opened = [0];
    // data

    // methods

    // computed
    get showMappingDifference() {
        console.log("Show mapping difference",this.mappingDifference?.allBrandValues?.length > 0, this.mappingDifference)
        return this.mappingDifference?.allBrandValues?.length > 0;
    }

    set showMappingDifference(value) {
        // do nothing
    }
}
</script>

<style scoped></style>
