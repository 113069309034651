import Vue from 'vue';
import Component from 'vue-class-component';
import { MappingStatusesEnum } from '@/shared/enums/mappingStatuses';

@Component
export default class HasMappingStatuses extends Vue {
    name = 'hasMappingStatuses';

    // data
    mappingStatuses = Object.values(MappingStatusesEnum);
}
