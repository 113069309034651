<template>
    <div class="text-center">
        <v-dialog v-model="isShown" width="500">
            <v-card>
                <v-card-title class="headline">
                    {{ title }}
                </v-card-title>

                <v-card-text>
                    {{ text }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="resolveTo(true)">
                        Ok
                    </v-btn>
                    <v-btn color="secondary" text @click="resolveTo(false)">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script type="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { YesNoDialogOptionsEnum } from '@/shared/enums/yesNoDialogOptions';
import { StoreMutationsEnum } from '@/shared/enums/storeMutations';

// TODO - add better style

@Component({
    computed: {
        ...mapState([
            'yesNoDialog',
        ]),
    },
    methods: {
        ...mapMutations([StoreMutationsEnum.displayYesNoDialog])
    }
})
export default class YesNoDialog extends Vue {
    name = 'yesNoDialog';

    // methods

    resolveTo(value) {
        this.displayYesNoDialog({ resolvedTo: value ? YesNoDialogOptionsEnum.ok : YesNoDialogOptionsEnum.cancel });
    }

    // computed

    get isShown() {
        return this.yesNoDialog.isShown;
    }

    set isShown(value) {
        this.resolveTo(value);
    }

    get title() {
        return this.yesNoDialog.title;
    }

    set title(value) {
        // do nothing
    }

    get text() {
        return this.yesNoDialog.text;
    }

    set text(value) {
        // do nothing
    }

}
</script>
<style scoped></style>
