import { FrontEndMapping } from '@/shared/interfaces/FrontEndMapping';

const getRelatedMappings = ({ mapping, mappings }: { mapping: FrontEndMapping; mappings: FrontEndMapping[] }): FrontEndMapping[] => {
    const relatedMappings: FrontEndMapping[] = [];

    for (const candidateMapping of mappings) {
        // mappings of different type cant be 'related'
        if (candidateMapping.type !== mapping.type) {
            continue;
        }

        // cant be related to itself
        if (candidateMapping._id === mapping._id) {
            continue;
        }

        // non-empty from is the same on another mapping
        if (candidateMapping.from !== '' && candidateMapping.from === mapping.from) {
            relatedMappings.push(candidateMapping);
            continue;
        }

        // non-empty to is the same on another mapping
        if (candidateMapping.to !== '' && candidateMapping.to === mapping.to) {
            relatedMappings.push(candidateMapping);
            continue;
        }
    }

    return relatedMappings;
};

export { getRelatedMappings };
