<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4" xl="3">
                <v-card class="elevation-12">
                    <v-toolbar color="blue-grey" dark flat>
                        <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="loginForm">
                            <v-text-field v-model="username" label="Username" name="login" prepend-icon="mdi-at" type="text" :rules="[rules.required]" />
                            <v-text-field
                                v-model="password"
                                label="Password"
                                name="password"
                                autocomplete="on"
                                prepend-icon="mdi-lock"
                                type="password"
                                :rules="[rules.required]"
                            />
                        </v-form>
                    </v-card-text>
                    <div class="d-flex justify-center">
                        <v-alert type="error" :value="credentialsInvalid" transition="scale-transition" text outlined>
                            You provided wrong username or password!
                        </v-alert>
                        <v-alert type="info" :value="expired" transition="scale-transition" text outlined>
                            Your session expired!
                        </v-alert>
                        <v-alert type="error" :value="timeout" transition="scale-transition" text outlined>
                            Problems connecting to server
                        </v-alert>
                    </div>
                    <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn large :loading="inAuthenticatingState" color="blue-grey" class="white--text px-4" @click="authenticate">
                            <v-icon class="mr-2">mdi-login</v-icon>
                            Login
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script type="ts">

import Component from 'vue-class-component';
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { StoreMutationsEnum } from '@/shared/enums/storeMutations';

@Component({
    methods: {
        ...mapMutations([StoreMutationsEnum.setUsername])
    }
})
export default class Login extends Vue {

    name = 'login';

    // data

    username = null;
    password = null;
    credentialsInvalid = false;
    expired = false;
    timeout = false;
    inAuthenticatingState = false; // used for login button loading button
    rules = {
        required: (value) => Boolean(value) || 'Required.'
    };

    // methods

    async authenticate() {
        this.credentialsInvalid = false;
        this.expired = false;
        this.timeout = false;
        this.inAuthenticatingState = true; // show loader spinning

        const loginResponse = await this.apiService.login({ username: this.username, password: this.password });

        const success = loginResponse?.success;
        const token = loginResponse?.token;

        if (success && token) {
            this.localStorageService.set('token', loginResponse?.token);
            this.localStorageService.set('username', this.username);
            this.$store.commit("setUsername", this.username);
            await this.$router.push('/');
        } else if (success === false && !token) { // TODO - add expired case
            this.credentialsInvalid = true;
        } else {
            this.timeout = true;
        }

        this.inAuthenticatingState = false; // stop loader spinning
    }

    // computed
}
</script>

<style scoped></style>
