const getEmptyMappingDifferenceItem = () => {
    return {
        allBrandValues: [],
        obsoleteMappings: [],
        currentMappingResults: [],
        newMappingResults: [],
        relatedMappings: [],
        mappingsContainingFrom: [],
    };
};

export { getEmptyMappingDifferenceItem };
