<template>
    <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-history
            </v-icon>
        </template>
        <span>{{ prettyPrintJson(tooltipText) }}</span>
    </v-tooltip>
</template>

<script type="ts">
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
    props: ['tooltipText']
})
export default class Tooltip extends Vue {
    name = 'tooltip';

    // data

    // methods
    prettyPrintJson(input) {
        return JSON.stringify(input, null, 2); // TODO - line breaks?
    }

    // computed
}
</script>
<style scoped></style>
