import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Mapping from '../components/Mapping.vue';
import Main from '../components/Main.vue';
import Login from '../components/Login.vue';
import isEqual from 'lodash.isequal';
import { localStorageService } from '@/services/localStorage.service';
import qs from 'qs';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    { path: '/login', component: Login, name: `Login`, meta: { requiresLogin: false } },
    { path: '/', component: Main, name: `List`, meta: { requiresLogin: true } },
    { path: '/mapping', component: Mapping, name: `Add new mapping`, meta: { requiresLogin: true } },
    { path: '/mapping/:id', component: Mapping, name: `Edit mapping`, meta: { requiresLogin: true } },
];

const router = new VueRouter({
    routes,
    mode: 'history',
    // base: process.env.BASE_URL, // TODO - add base url for app
    // set custom query resolver
    parseQuery: (query) => {
        return qs.parse(query);
    },
    stringifyQuery: (query) => {
        const result = qs.stringify(query, { encode: false });

        return result ? '?' + result : '';
    },
});

router.beforeEach((to, from, next) => {
    const fromQuery = new Map(Object.entries(to.query));
    const toQuery = new Map(Object.entries(from.query));
    const pathsAreSame = from.path === to.path;
    const queriesAreSame = isEqual(fromQuery, toQuery);

    // navigation can be duplicated
    if (pathsAreSame && queriesAreSame) {
        console.log('router - beforeEach - Paths and queries are the same');
    }

    // TODO - watch for duplicate routing

    if (!pathsAreSame) {
        // only log 'real' route change
        console.log(`from: "${from.path}" -> to: "${to.path}"`);
    }

    const isAuthenticated = localStorageService.get('token');

    if (!isAuthenticated && to.path !== '/login') {
        return next('/login');
    } else {
        return next();
    }
});

export default router;
