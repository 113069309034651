import moment from 'moment';

const formatDate = (format = 'MM/DD/YYYY hh:mm') => {
    return (value: string) => {
        if (value) {
            return moment(value?.toString()).format(format);
        }
    };
};

export { formatDate };
