import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import { apiService } from '@/services/api.service';
import { localStorageService } from '@/services/localStorage.service';
import { entitiesService } from '@/services/entities.service';
import { formatDate } from '@/filters/formatDate';

Vue.config.productionTip = false;

Vue.filter('formatDate', formatDate());
Vue.filter('formatDateShort', formatDate('DD.MM.YYYY'));

Vue.prototype.apiService = apiService;
Vue.prototype.localStorageService = localStorageService;
Vue.prototype.entitiesService = entitiesService;

new Vue({
    vuetify,
    render: (h) => h(App),
    router,
    store,
    data: {},
    async created() {
        console.log('VUE APP STARTED');
        if (localStorageService.get('userName')) {
            this.$store.commit('setUsername', localStorageService.get('userName'));
            await this.$store.dispatch('fetchMappings');
            await this.$store.dispatch('fetchB2bVehicles');
            setInterval(async () => {
                await this.$store.dispatch('fetchMappings');
            }, 60000);
            setInterval(async () => {
                await this.$store.dispatch('fetchB2bVehicles');
            }, 3600000);
        }
    },
}).$mount('#app');
