import Vue from 'vue';
import Component from 'vue-class-component';
import { AppTypesEnum } from '@/shared/enums/appTypes';

@Component
export default class HasAppTypes extends Vue {
    name = 'hasAppTypes';

    // data
    appTypes = Object.values(AppTypesEnum);
}
