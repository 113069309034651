<template>
    <v-snackbar v-model="isShown" :timeout="3000" :color="color">
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="resolveTo">Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script type="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { StoreMutationsEnum } from '@/shared/enums/storeMutations';

@Component({
    computed: {
        ...mapState([
            'snack',
        ]),
    },
    methods: {
        ...mapMutations([StoreMutationsEnum.clearSnack])
    }
})
export default class Toaster extends Vue {
    name = 'toaster';

    // data

    // methods
    resolveTo(value) {
        this.clearSnack({ resolvedTo: value });
    }

    // computed
    get isShown() {
        return this.snack.isShown;
    }

    set isShown(value) {
        this.resolveTo(value);
    }

    get text() {
        return this.snack.text;
    }

    set text(value) {
        // do nothing
    }

    get color() {
        return this.snack.color;
    }

    set color(value) {
        // do nothing
    }

}
</script>
<style scoped></style>
