<template>
    <v-container>
        <v-container v-if="loading" class="d-flex justify-space-around align-content-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-container>

        <v-container v-if="!loading">
            <div class="d-flex justify-end">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">
                            <v-icon>mdi-hammer-wrench</v-icon>
                            <span class="ml-1">Developer tools</span>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-btn color="primary" dark class="mb-2" @click="refreshVehicles()">
                                <v-icon>mdi-refresh</v-icon>
                                Refresh vehicles
                            </v-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-btn color="primary" dark class="mb-2" @click="triggerMappingsCopy()">
                                <v-icon>mdi-alert</v-icon>
                                Copy old mappings
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-row>
                <v-col>
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="filteredMappings({ appType, mappingType, mappingStatus })"
                            class="elevation-1"
                            multi-sort
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDirection"
                            :loading="tableLoading"
                            :search="search"
                            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
                            :items-per-page="30"
                            append-icon="mdi-magnify"
                        >
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="pt-3">
                                    <v-text-field v-model="search" label="Search in mappings"></v-text-field>

                                    <v-divider class="mx-4" inset vertical></v-divider>

                                    <v-select style="max-width: 150px;" v-model="appType" :items="appTypes" label="Select app type"></v-select>

                                    <v-divider class="mx-4" inset vertical></v-divider>

                                    <v-select style="max-width: 150px;" v-model="mappingType" :items="mappingTypes" label="Select type"></v-select>

                                    <v-divider class="mx-4" inset vertical></v-divider>

                                    <v-select style="max-width: 150px;" v-model="mappingStatus" :items="mappingStatuses" label="Select status"></v-select>

                                    <v-spacer></v-spacer>

                                    <v-btn to="/mapping">Add new mapping</v-btn>
                                </v-toolbar>
                            </template>

                            <template v-slot:item.createdAt="props">
                                <span class="text--secondary"> {{ props.item.createdAt | formatDateShort }}</span>
                            </template>

                            <template v-slot:item.updatedAt="props">
                                <span class="text--secondary"> {{ props.item.updatedAt | formatDateShort }}</span>
                            </template>

                            <template v-slot:item.partial="props">
                                <span class="text--secondary"> {{ props.item.partial ? 'Partial' : 'Exact' }}</span>
                            </template>

                            <template v-slot:item.urls="props">
                                <a v-if="Array.isArray(props.item.urls) && props.item.urls.length > 0" :href="props.item.urls[0]" target="_blank">
                                    <v-icon>mdi-link</v-icon>
                                </a>
                            </template>

                            <template v-slot:item.sites="props">
                                <span class="text--secondary">{{
                                    Array.isArray(props.item.sites) && props.item.sites.length > 0 ? props.item.sites.join(', ') : 'All'
                                }}</span>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <div class="d-flex flex-row text-center justify-space-around">
                                    <v-btn class="mr-1" icon elevation="2" :to="`/mapping/${encodeURIComponent(item._id)}`">
                                        <v-icon small>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>

                                    <v-btn class="mr-1" icon elevation="2" @click="openDialog({ action: 'hide', mapping: item })">
                                        <v-icon small>
                                            mdi-eye-off
                                        </v-icon>
                                    </v-btn>

                                    <v-btn class="" icon dark elevation="2" color="pink" @click="openDialog({ action: 'delete', mapping: item })">
                                        <v-icon small>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </template>

                            <template v-slot:no-data>
                                <v-btn color="primary" @click="refreshMappings()">Refresh data</v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script type="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import hasMappingTypes from '@/mixins/hasMappingTypes';
import hasAppTypes from '@/mixins/hasAppTypes';
import hasMappingStatuses from '@/mixins/hasMappingStatuses';
import hasMappingCrud from '@/mixins/hasMappingCrud';
import { YesNoDialogOptionsEnum } from '@/shared/enums/yesNoDialogOptions';
import { YesNoDialogActionsEnum } from '@/shared/enums/yesNoDialogActions';
import { StoreGettersEnum } from '@/shared/enums/storeGetters';
import { StoreMutationsEnum } from '@/shared/enums/storeMutations';
import { StoreActionsEnum } from '@/shared/enums/storeActions';

@Component({
    computed: {
        ...mapState(['yesNoDialog']),
        ...mapGetters([StoreGettersEnum.filteredMappings]),
    },
    methods: {
        ...mapMutations([StoreMutationsEnum.displayYesNoDialog, StoreMutationsEnum.clearYesNoDialog]),
        ...mapActions([StoreActionsEnum.fetchMappings]),
    }
})
export default class Main extends mixins(hasMappingTypes, hasAppTypes, hasMappingStatuses, hasMappingCrud) {
    name = 'main';

    // sortDirection = [];
    // data
    headers = [
        { text: 'Created', value: 'createdAt', sort: this.dateSort},
        { text: 'Updated', value: 'updatedAt', sort: this.dateSort},
        { text: 'Type', value: 'type', align: 'start' },
        { text: 'Value', value: 'from' },
        { text: 'B2B value', value: 'to' },
        { text: 'Sites', value: 'sites' },
        { text: 'Brand', value: 'brands' },
        { text: 'Partial', value: 'partial' },
        { text: 'URL', value: 'urls' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
    ];

    sortDirection = [];

    loading = false;
    tableLoading = false; // TODO - remove if this unused?

    // methods

    created() {
        this.mappingTypes.unshift("All");
        this.mappingStatuses.unshift("All");
        this.appTypes.unshift("All");

        // Set the sortDirection from the url if present
        // This is not in the getter, because we want this to happen only the first time we enter the page
        if (typeof this.$route.query.sortDirection !== 'undefined') {
            this.sortDirection = (Array.isArray(this.$route.query.sortDirection) ? this.$route.query.sortDirection : [this.$route.query.sortDirection]).map(el => el === 'true');
        }
    }


    async mounted() {
        this.tableLoading = true;
        await this.fetchMappings();
        this.tableLoading = false;
    }

    openDialog({ action, mapping }) {

        let text = YesNoDialogActionsEnum.unsupported;
        if (action === "delete") {
            text = YesNoDialogActionsEnum.delete;
        } else if (action === "hide") {
            text = YesNoDialogActionsEnum.hide;
        }

        this.displayYesNoDialog({
            text,
            context: this.name,
            params: { action, item: mapping },
        });
    }

    async refreshMappings() {
        await this.fetchMappings({ force: true });
    }

    async refreshVehicles() {
        await this.apiService.refreshVehicles();
    }

    async triggerMappingsCopy() {
        await this.apiService.triggerMappingsCopy();
    }

    dateSort(a, b) {
        const dateA = new Date(a);
        const dateB = new Date(b);

        dateA.setHours(0,0,0,0)
        dateB.setHours(0,0,0,0)

        return dateA - dateB;
    }



    // watchers

    @Watch('yesNoDialog', { deep: true})
    async watchYesNoDialogResolvedTo({ resolvedTo, params }) {

        if (!resolvedTo) {
            // to prevent infinite loop
            return;
        }

        this.clearYesNoDialog();

        if (resolvedTo !== YesNoDialogOptionsEnum.ok) {
            return;
        }

        const action = params?.action;
        const item = params?.item;

        if (action === "hide") {
            await this.hideMapping({ mapping: item });
        } else if (action === "delete") {
            await this.deleteMapping({ mapping: item });
        }

        await this.fetchMappings({ force: true });
    }

    @Watch('sortDirection', { deep: true})
    async redirectOnSortDirectionChange(value) {
        // Update the url everytime the sort direction changes
        if (this.$route?.query?.sortDirection?.toString() !== value.toString()) {
            await this.$router.replace({
                query: { ...this.$route.query, sortDirection: value },
            });
        }
    }

    get sortBy() {
        //
        if (typeof this.$route.query.sortBy !== 'undefined') {
            return Array.isArray(this.$route.query.sortBy) ? this.$route.query.sortBy : [this.$route.query.sortBy]
        }
        return [];
    }

    set sortBy(value) {
        // Updates the url param if the sortby changes
        if (JSON.stringify(this.$route.query.sortBy) !== JSON.stringify(value)) {
            const sortByArray = Array.isArray(value) ? value : [value];
            this.$router.replace({
                query: { ...this.$route.query, sortBy: sortByArray },
            });
        }
    }

    get mappingType() {
        if (!this.$route.query.mappingType) {
            this.$router.replace({
                query: { ...this.$route.query, mappingType: 'All' },
            });
        }
        return this.$route.query.mappingType;
    }

    set mappingType(value) {
        if (this.$route.query.mappingType !== value) {
            this.$router.replace({
                query: { ...this.$route.query, mappingType: value },
            });
        }
    }

    get mappingStatus() {
        if (!this.$route.query.mappingStatus) {
            this.$router.replace({
                query: { ...this.$route.query, mappingStatus: 'All' },
            });
        }
        return this.$route.query.mappingStatus;
    }

    set mappingStatus(value) {
        if (this.$route.query.mappingStatus !== value) {
            this.$router.replace({
                query: { ...this.$route.query, mappingStatus: value },
            });
        }
    }

    get appType() {
        if (!this.$route.query.appType) {
            this.$router.replace({
                query: { ...this.$route.query, appType: 'All' },
            });
        }
        return this.$route.query.appType;
    }

    set appType(value) {
        if (this.$route.query.appType !== value) {
            this.$router.replace({
                query: { ...this.$route.query, appType: value },
            });
        }
    }

    get search() {
        if (!this.$route.query.search && this.$route.query.search !== '') {
            this.$router.replace({
                query: { ...this.$route.query, search: '' },
            });
        }
        return this.$route.query.search;
    }

    set search(value) {
        if (this.$route.query.search !== value) {
            this.$router.replace({
                query: { ...this.$route.query, search: value },
            });
        }
    }

    get showUnresolvedMappings() {
        if (!this.$route.query.showUnresolvedMappings) {
            this.$router.replace({
                query: { ...this.$route.query, showUnresolvedMappings: '0' },
            });
        }
        return this.$route.query.showUnresolvedMappings !== '0';
    }

    set showUnresolvedMappings(value) {
        if (this.$route.query.showUnresolvedMappings !== value) {
            this.$router.replace({
                query: { ...this.$route.query, showUnresolvedMappings: value ? '1' : '0' },
            });
        }
    }

    get showMlApiMappings() {
        if (!this.$route.query.showMlApiMappings) {
            this.$router.replace({
                query: { ...this.$route.query, showMlApiMappings: '0' },
            });
        }
        return this.$route.query.showMlApiMappings !== '0';
    }

    set showMlApiMappings(value) {
        if (this.$route.query.showMlApiMappings !== value) {
            this.$router.replace({
                query: { ...this.$route.query, showMlApiMappings: value ? '1' : '0' },
            });
        }
    }

}
</script>

<style scoped>
th {
    vertical-align: center;
}

.v-data-table-header th {
    white-space: nowrap;
}
</style>
