export enum SitesEnum {
    AHM = 'ahm',
    ARAMIS = 'auto-aramis',
    'AUTI-HR' = 'auti-hr',
    AUTOSCOUT = 'autoscout',
    AVTONET = 'avto-net',
    BRUEGGEMANN = 'brueggemann',
    ELITE = 'auto-elite',
    ICI = 'auto-ici',
    IES = 'auto-ies',
    'INDEX-HR' = 'index-hr',
    LACENTRALE = 'lacentrale',
    MOBILE = 'mobile',
    PROMONEUVE = 'promo-neuve',
    QARSON = 'qarson',
    RASTETTER = 'rastetter',
    SIXTCARSALESDE = 'sixtcarsales',
    STARTERRE = 'star-terre',
}
