const normalizeString = (input: string) => {
    return (
        input
            ?.trim()
            ?.toLowerCase()
            ?.replace(/\n/g, ' ')
            ?.normalize('NFD')
            ?.replace(/[\u0300-\u036f]/g, '') || input
    );
};

export { normalizeString };
